import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import LoadingScreen from './LoadingScreen';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const router = useRouter();

  if (isAuthenticated) {
    router.push('/app');
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
